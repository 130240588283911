@charset "utf-8";

$x-large: 1600px;

/* Base16 Google Dark highlighting */
$base00: #1d1f21;
$base01: #282a2e;
$base02: #373b41;
$base03: #969896;
$base04: #b4b7b4;
$base05: #c5c8c6;
$base06: #e0e0e0;
$base07: #ffffff;
$base08: #CC342B;
$base09: #F96A38;
$base0A: #FBA922;
$base0B: #198844;
$base0C: #3971ED;
$base0D: #3971ED;
$base0E: #A36AC7;
$base0F: #3971ED;

@import "minimal-mistakes/skins/dark"; // skin
@import "minimal-mistakes"; // main partials

html {
  font-size: 14px;

  @include breakpoint($medium) {
    font-size: 16px;
  }

  @include breakpoint($large) {
    font-size: 18px;
  }

  @include breakpoint($x-large) {
    font-size: 20px;
  }
}